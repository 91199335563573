import {
    getVariantNameSchema,
    getVariantSchema,
    getProductGroupDescriptionSchema,
    getProductDescriptionSchema,
    getFullUrl,
    calculateProductPrice,
} from './schemaGenerationUtils';

/**
 * Constructs the offers schema for a product SKU.
 *
 * @param {Object} sku - The specific SKU object for which the offer is being generated.
 * @param {string} href - The relative URL to the product, used to construct the full offer URL.
 * @returns {Object} A structured data object representing the offer, formatted according to Schema.org standards.
 */
export const getOffers = (productData, href, promotionData) => {
    const noSkuProduct = productData?.sKUs?.length === 0;
    const salePrice = promotionData?.promotionApplied === 'auto' || promotionData?.promotionApplied === 'manual';
    const promoPrice = salePrice && parseFloat(promotionData?.marketingProperties?.FinalPriceTotal)?.toFixed(2);

    if (!productData) {
        return {};
    }

    return {
        '@type': 'Offer',
        url: href || '',
        itemCondition: 'https://schema.org/NewCondition',
        availability: noSkuProduct
            ? productData?.buyable?.canAddToBag
                ? 'https://schema.org/InStock'
                : 'https://schema.org/OutOfStock'
            : productData?.buyable
              ? 'https://schema.org/InStock'
              : 'https://schema.org/OutOfStock',
        priceSpecification: salePrice
            ? [
                  {
                      '@type': 'UnitPriceSpecification',
                      price: promoPrice,
                      priceCurrency: productData?.price?.currency?.label,
                      valueAddedTaxIncluded: 'https://schema.org/True',
                  },
                  {
                      '@type': 'UnitPriceSpecification',
                      priceType: 'https://schema.org/ListPrice',
                      price: calculateProductPrice(productData?.price),
                      priceCurrency: productData?.price?.currency?.label,
                      valueAddedTaxIncluded: 'https://schema.org/True',
                  },
              ]
            : {
                  price: noSkuProduct ? productData?.price?.finalPrice?.toFixed(2) : calculateProductPrice(productData?.price),
                  priceCurrency: productData?.price?.currency?.label || '',
                  valueAddedTaxIncluded: 'https://schema.org/True',
              },
        hasMerchantReturnPolicy: {
            returnPolicyCategory: 'http://schema.org/MerchantReturnFiniteReturnWindow',
        },
    };
};

/**
 * Constructs an array of list items for the visible products in the state. Each list item is an object that includes
 * product details and an offer object.
 *
 * @param {Object} config - The state config object.
 * @param {Array} products - The list of products displayed on the page.
 * @returns {Array} An array of list item objects.
 */
export const getItemList = (config, products = []) => {
    const itemList = products.map((product, index) => {
        return {
            '@type': 'ListItem',
            position: index + 1,
            item: {
                '@type': 'Product',
                url: getFullUrl(config, product?.href),
                mainEntityOfPage: getFullUrl(config, product?.href),
                productID: product?.partNumber || '',
                image: product?.imgSrc?.primary || '',
                name: product?.name || '',
                brand: {
                    '@type': 'Brand',
                    name: product?.designerName || '',
                },
                offers: [
                    {
                        '@type': 'Offer',
                        price: product?.price?.finalPriceWithoutCurrencySymbol || '',
                        priceCurrency: product?.price?.currency?.label || '',
                        // NOTE: could maybe hack this to check sold out badge. PLP visibility is automatically calculated to buyable product, except some forced exceptions.
                        availability: 'https://schema.org/InStock',
                    },
                ],
            },
        };
    });
    return itemList;
};

/**
 * Generates a structured product schema.
 *
 * @param {Object} config - An object containing configuration settings.
 * @param {Object} product - The primary product object for which the schema is being generated.
 * @param {Object} messages - The messages object containing the translations for the current locale.
 * @param {Object} metaData - The metaData object, used for SEO.
 * @returns {Array} An array of schema objects for each SKU of the first product's colours.
 */
export const getProductSchema = (config, product, messages, metaData, promotionData) => {
    if (!product) {
        return {};
    }

    const color = product?.label?.toLowerCase() || '';
    const href = metaData?.canonicalUrl || '';
    const isTONBrand = config?.global?.application?.brandId === 'theoutnet';
    const isNAPBrand = config?.global?.application?.brandId === 'nap';
    const variantName = getVariantNameSchema(product);

    // for sold out products without sku data
    if (product?.sKUs?.length === 0) {
        const skuSize = messages?.sizes?.oneSizeLabel() || '';

        return [
            {
                '@context': 'http://schema.org/',
                '@type': 'Product',
                sku: product.partNumber || '',
                image: metaData?.og?.image || '',
                name: `${product?.name} - ${color} - ${skuSize}` || '',
                description: getProductDescriptionSchema(product, [], skuSize, messages) || '',
                color: color || '',
                size: skuSize || '',
                offers: getOffers(product, href) || '',
                brand: {
                    '@type': 'Brand',
                    name: product?.designerName || '',
                },
                material: product?.composition,
                audience: isTONBrand
                    ? undefined
                    : variantName === 'singleColorSize'
                      ? {
                            '@type': 'PeopleAudience',
                            suggestedGender: product?.analyticsAttributes?.gender || isNAPBrand ? 'women' : 'men',
                        }
                      : undefined,
            },
        ];
    }

    // shows all the skus for the selected colour
    const skus = product.sKUs.map((sku) => {
        const label = sku?.size?.centralSizeLabel || sku?.size?.labelSize;
        const isOneSize = product?.isOneSize || !product?.hasMeaningfulSize;
        const skuSize = isOneSize ? messages?.sizes?.oneSizeLabel() : label || '';

        return {
            '@context': 'http://schema.org/',
            '@type': 'Product',
            sku: sku?.partNumber || '',
            image: metaData?.og?.image || '',
            name: `${product?.name} - ${color} - ${skuSize}` || '',
            description: getProductDescriptionSchema(product, sku, skuSize, messages) || '',
            color: color || '',
            size: skuSize,
            offers: getOffers(sku, href, promotionData) || '',
            brand: {
                '@type': 'Brand',
                name: product?.designerName || '',
            },
            material: product?.sKUs[0]?.composition,
            audience: isTONBrand
                ? undefined
                : variantName === 'singleColorSize'
                  ? {
                        '@type': 'PeopleAudience',
                        suggestedGender: product?.analyticsAttributes?.gender || isNAPBrand ? 'women' : 'men',
                    }
                  : undefined,
        };
    });

    // shows the urls for the other colourSwatches
    const urls =
        product.colourSwatches
            ?.filter((product) => product.selected === false)
            ?.map((notSelected) => {
                const href = notSelected.href || '';
                return {
                    url: getFullUrl(config, href) || '',
                };
            }) || [];

    return [...skus, ...urls];
};

/**
 * Constructs the Product Detail Page (PDP) schema metadata object.
 *
 * @param {Object} config - Configuration object containing settings and preferences.
 * @param {Object} product - The primary product object for which the schema is being generated.
 * @param {Object} messages - The messages object containing the translations for the current locale.
 * @param {Object} metaData - The metaData object, used for SEO.
 * @param {Object} appState - The state object containing the current application state.
 * @returns {Object} The schema metadata object tailored for the PDP, which can be a single product schema or a product group schema depending on the product variants.
 */
export const getPDPSchemaMetaData = (config, product, messages, metaData, promotionData) => {
    if (!product) {
        return {};
    }

    const variantName = getVariantNameSchema(product);
    const productSchemas = getProductSchema(config, product, messages, metaData, promotionData) || '';
    const isTONBrand = config?.global?.application?.brandId === 'theoutnet';
    const isNAPBrand = config?.global?.application?.brandId === 'nap';

    if (variantName === 'singleColorSize') {
        const productSchema = productSchemas[0];

        return productSchema;
    }

    return {
        '@context': 'https://schema.org',
        '@type': 'ProductGroup',
        name: product?.name || 'undefined',
        brand: {
            '@type': 'Brand',
            name: product?.designerName || '',
        },
        description: getProductGroupDescriptionSchema(product, messages) || '',
        variesBy: getVariantSchema(product),
        material: product?.sKUs[0]?.composition,
        audience: isTONBrand
            ? undefined
            : {
                  '@type': 'PeopleAudience',
                  suggestedGender: product?.analyticsAttributes?.gender || isNAPBrand ? 'women' : 'men',
              },
        productGroupId: product?.modelPartNumber || '',
        hasVariant: productSchemas,
    };
};

/**
 * Constructs the PLP schema metadata object based on the state object.
 *
 * @param {Object} config - The state config object.
 * @param {Array} products - The list of products displayed on the page.
 * @param {Object} metaData - The metaData object, used for SEO.
 * @param {Object} listingResponse - The raw PLP API response object.
 * @returns {Object} The schema metadata object.
 */
export const getPLPSchemaMetaData = (config, products, metaData, listingResponse) => {
    return {
        '@context': 'https://schema.org',
        '@type': 'CollectionPage',
        mainEntity: {
            '@type': 'ItemList',
            url: metaData?.canonicalUrl || '',
            numberOfItems: listingResponse?.recordSetTotal || '',
            itemListElement: getItemList(config, products),
        },
    };
};

/**
 * Constructs an array of brand objects for the designers in the state. Each brand object includes the designer's URL and name.
 *
 * @param {Object} config - The state config object.
 * @param {Array} designers - The list of designers.
 * @returns {Array} An array of brand objects.
 */
export const getDAZSchemaMetaData = (config, designers) => {
    const designersData = designers?.map((designer) => {
        return {
            '@context': 'https://schema.org',
            '@type': 'Brand',
            url: getFullUrl(config, designer?.href),
            name: designer?.designerName || '',
        };
    });

    return designersData;
};
